import logo from "../assets/logos.png"
const Logos = () => {
    return (  
        <section>
<div className="flex justify-center gap-5 my-32">

<img src={logo} alt="logos" className="w-10/12 md:w-4/12" />

</div>
        </section>
    );
}
 
export default Logos;
import Puzzle from "../animations/Puzzle";
const Hero = () => {
    return ( 
        <section className="my-32 md:my-40 flex flex-col md:flex-row justify-between items-center gap-5">
            <div className="md:w-5/12">
            <h1 className="text-3xl md:text-5xl font-bold text-primary2">Careers</h1>
            <p className="text-lg w-10/12 md:mt-5 leading-7">
            We give our employees the opportunity to advance in their careers.
            </p>
            </div>
           <div className="flex md:justify-end justify-center md:w-6/12">
           <Puzzle />

           </div>
        </section>
     );
}
 
export default Hero;
import { useRef } from "react";
import emailjs from '@emailjs/browser';

const Form = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1g3pmop', 'template_0cp0giy', form.current, 'JTA67EjzERFsK2WcP')
          .then((result) => {
             alert("Thank you for contacting us. We will get back to you shortly.");
          }, (error) => {
              alert("Something went wrong. Please try again later.");
          });
      };
    return ( 


        <section className="mt-32 flex flex-col gap-10" id = "contact">
            <h3 className="text-2xl font-bold leading-8 text-primary2 md:text-4xl lg:leading-relaxed text-center md:w-8/12 mx-auto">
            Apply if you think you are passionate on learning - Apply here
            </h3>
            <form action="" className="flex flex-col gap-5 w-full md:w-8/12 mx-auto" ref={form} onSubmit = {sendEmail}>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Name</label>
                    <input type="text" className="form-input" required name="name"/>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Phone Number</label>
                    <input type="phone" className="form-input" required name="phone"/>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Email Address</label>
                    <input type="email" className="form-input" required name="email"/>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Linkedin URL</label>
                    <input type="url" className="form-input" required name="linkedin"/>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Portfolio URL (Behance, Dribbble, Dropbox, Google Drive, etc.)</label>
                    <input type="url" className="form-input" required name="portfolio"/>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className="">Why should we select you?</label>
                    <textarea name="message" id="" cols="30" rows="10" className="form-input" required></textarea>
                </div>
                <button className="btn">Action</button>
            </form>
        </section>
     );
}
 
export default Form;
import { useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

const Navbar = () => {


  const menu = useRef()



const handleMenu = () => {
menu.current.classList.toggle("h-0")
menu.current.classList.toggle("h-56")

}


const location = useLocation();
useEffect(() => {
   document.querySelector(".scroll-to").scrollIntoView({behavior : "smooth"})
},[location])

  return (
    <>
          <div className="topScroll scroll-to absolute top-0 left-0"></div>

      {/* //  MOBILE NAV */}
      <nav className="md:hidden fixed top-0 -translate-x-1/2 left-1/2  w-full h-16 z-10 border-b " >
        <div className="py-5 flex justify-between items-center w-11/12 mx-auto border-b h-16">
        <Link to="/" className = "relative z-10">
          <img src={logo} alt="calpyte" className="w-28" />
        </Link>
        <div className="" >
          <svg
          onClick={handleMenu}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="burger-nav">
              <path
                id="Icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 8H26V10H6V8ZM6 15H26V17H6V15ZM26 22H6V24H26V22Z"
                fill="#3742FA"
              />
            </g>
          </svg>
        </div>
        </div>
       <div className="w-full nav">
       <div className="flex flex-col items-start gap-5 h-0 overflow-hidden transition-all w-10/12 mx-auto -mt-3 pt-3" ref = {menu}>
            <NavLink to="/services" className="font-medium">
              Services
            </NavLink>
            <NavLink to="/about-us" className="font-medium">
              About us
            </NavLink>
            <NavLink to="/careers" className="font-medium">
              Careers
            </NavLink>
            <NavLink to = "/contact-us" className="font-medium">
              Contact us
            </NavLink>

          </div>
       </div>
     
      </nav>

      {/* // DESKTOP NAV & TABLET NAV */}
      <nav className="hidden md:flex py-8 px-1 justify-between items-center fixed top-0 -translate-x-1/2 left-1/2  w-full  h-16 border-b z-10">
        <div className="w-10/12 mx-auto flex justify-between items-center relative inner-nav">
        <Link to="/" className = "">
          <img src={logo} alt="calpyte" className="w-28" />
        </Link>
        <div className="flex items-center gap-10 text-lg font-medium">
          <NavLink to="/services" className="main-nav">
            Services
          </NavLink>
          <NavLink to="/about-us" className = "main-nav">About us</NavLink>
          <NavLink to="/careers" className = "main-nav">Careers</NavLink>
          <Link to = "/contact-us" className="font-medium btn text-white">
              Contact us
            </Link>

          
        </div>
        </div>
    
      </nav>
    </>
  );
};

export default Navbar;

import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { LinkedinLogo } from "phosphor-react";
import { InstagramLogo } from "phosphor-react";
import { TwitterLogo } from "phosphor-react";
const Footer = () => {
  return (
    //Mobile footer
    <>
      <footer className="flex flex-col items-center gap-8 md:hidden mt-24 pb-5">
        <Link to="">
          <img src={logo} alt="" className="w-6/12 mx-auto" />
        </Link>
        <div className="flex flex-col items-center gap-5">
          <NavLink to="/services" className="font-medium">
            Services
          </NavLink>
          <NavLink to="/about-us" className="font-medium">
            About us
          </NavLink>
          <NavLink to="/careers" className="font-medium">
            Careers
          </NavLink>
          <NavLink to="/privacy-policy" className="font-medium">
              Privacy Policy
            </NavLink>
        </div>

        <div className="flex gap-5 ">
        <a href="https://www.linkedin.com/company/calpytetechnology" target = "_blank" rel="noreferrer" className="">
              <LinkedinLogo size={32} />
            </a>
           <a href="https://www.instagram.com/calpyteofficial/" target = "_blank" rel="noreferrer" className="">
              <InstagramLogo size={32} />
           </a>
           <a href="https://www.twitter.com/calpyte" target="_blank" rel="noreferrer" className="">
             <TwitterLogo size={32} />
           </a>

        </div>
        <div className="flex flex-col gap-5 items-center">
          <p className="text-gray-400">
            © {new Date().getFullYear()} Calpyte. All right reserved.
          </p>
        </div>
      </footer>

      {/* Desktop */}
      <footer className="hidden md:block mt-20">
        <div className="flex justify-between items-center py-4">
          <Link to="">
            <img src={logo} alt="calpyte-logo" className="w-6/12" />
          </Link>
          <div className="flex items-center gap-5">
            <NavLink to="/services" className="font-medium">
              Services
            </NavLink>
            <NavLink to="/about-us" className="font-medium">
              About us
            </NavLink>
            <NavLink to="/careers" className="font-medium">
              Careers
            </NavLink>
            <NavLink to="/privacy-policy" className="font-medium">
              Privacy Policy
            </NavLink>
          </div>
        </div>
        <div className="flex justify-between items-center mb-3">
          <p className="text-gray-400">
            © {new Date().getFullYear()} Calpyte. All right reserved.
          </p>

          <div className="flex items-center gap-8 py-5">
            <a href="https://www.linkedin.com/company/calpytetechnology" target = "_blank" rel="noreferrer" className="">
              <LinkedinLogo size={32} />
            </a>
           <a href="https://www.instagram.com/calpyteofficial/" target = "_blank" rel="noreferrer" className="">
              <InstagramLogo size={32} />
           </a>
           <a href="https://www.twitter.com/calpyte" target="_blank" rel="noreferrer" className="">
             <TwitterLogo size={32} />
           </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import Graph from "../animations/Graph";

const Ourservices = () => {
    return ( 
        <section className="my-32 md:my-40 flex flex-col gap-10 md:flex-row md:justify-between justify-center items-center mx-auto ">
            <div className="md:w-6/12 ">
            <h1 className="text-3xl md:text-5xl font-bold text-primary2">Our Services</h1>
            <p className="text-lg md:mt-5 leading-7">
            Technology is the only way to make the future sustainable for your business.
            </p>
            </div>
           
           <div className="w-full md:w-8/12 flex justify-center md:justify-end">
           <Graph />
           </div>
          
        </section>
     );
}
 
export default Ourservices;
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Privacy from "./pages/Privacy";
import Page404 from "./pages/Page404";
import ReactGA from 'react-ga4';
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
const TRACKING_ID = "UA-221533880-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  return (
    <>
      <div className="App mx-auto w-11/12 md:w-full text-primary1">
        <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path = "/privacy-policy" element = {<Privacy />} />
            <Route path = "*" element = {<Page404/>} />
            <Route path = "/contact-us" element = {<Contact />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>

     
    </>
  );
}

export default App;

import { useLocation } from "react-router-dom";

 
const Page404 = () => {
    const location = useLocation()
    console.log(location.pathname)
    return ( 
        <>
        
        <section className=" flex flex-col gap-5 items-center justify-center h-96 mt-16">
            
            <h1 className = "text-2xl md:text-6xl text-primary2">404</h1>
            <p className="text-lg md:text-2xl">{`Page "${location.pathname}" is Not Found`}</p>
        </section>
        </>
     );
}
 
export default Page404;
import Helmet from "react-helmet";
import AboutHero from "../about/AboutHero";
import Benifits from "../about/Benifts";
import OurTeam from "../about/OurTeam";
import Primary from "../about/Primary";
import Quotes from "../about/Quote";
import Forms from "../components/Forms";

const About = () => {
    return ( 
        <>
        <Helmet>
            <title>Calpyte - About us</title>
            <meta name = "description" content="Our Vision - To encode technology and future-proof business transformation"/>
        </Helmet>
        <AboutHero />
        <Primary />
        <Benifits />
        <Quotes />
        <OurTeam />
        <Forms title = "Let's Talk" content="Feel free contact us and let's work together to knock down barriers for your company." />
        </>
     );
}
 
export default About;
import caro1 from "../assets/caro-1.jpg";
const Carousal = () => {


  return (
    <section className="flex flex-col gap-10 md:flex-row items-center mt-32">
      <div className="flex flex-col gap-5 md:w-6/12">
        <h3 className="text-2xl font-bold leading-8 text-primary2 md:text-4xl lg:leading-relaxed">
          Life at calpyte
        </h3>
        <p className="text-lg">
          Calpyte is a very own learning and a growing place where you will be
          upskilled in way that it would change the perception of your life and
          will give a new meaning
        </p>
      </div>
      <div className="sm:w-6/12 relative">

        <div className="overflow-hidden w-full rounded-md">
          <div className="flex w-full transform  transition-transform duration-300">
            <img src={caro1} alt="carosel-1" className="" />
           
          </div>
        
        </div>

      </div>
    </section>
  );
};

export default Carousal;

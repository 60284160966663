import Helmet from "react-helmet";
import Forms from "../components/Forms";
import Navbar from "../components/Navbar";
import Ourservices from "../services/Ourservices";
import PrimaryServices from "../services/PrimaryServices";
import SecondaryService from "../services/SecondaryService";
import TertiaryServices from "../services/TertiaryServices";


const Services = () => {
    return ( 
        <>
    <Helmet>
        <title>Calpyte - Services</title>
        <meta name = "description" content="Our Services - Technology is the only way to make the future sustainable for your business."/>
    </Helmet>
      
              <Navbar/>


        <Ourservices />
        <PrimaryServices />

        <SecondaryService />


        <TertiaryServices />
     <Forms title = "Let's Talk" content="Feel free contact us and let's work together to knock down barriers for your company." />
        </>
     );
}
 
export default Services;
import heroImage from "../assets/hero-image.png";

const Hero = () => {
  return (
    <header className="my-32 md:my-32 flex flex-col md:flex-row-reverse justify-between items-center gap-10">
      <img src={heroImage} alt="hero" className=" sm:w-5/12" />
      <div className="flex flex-col gap-6 md:w-6/12">
        <h1 className="text-4xl md:text-6xl font-bold leading-20">Encoding the Future</h1>
        <div className="flex flex-col gap-6">
          <h2 className="text-base md:text-lg font-normal md:font-medium">
            The only Technology Partner You'll Ever Need
          </h2>
        </div>

        <div className="flex flex-col gap-6 md:flex-row mt-6">
          <a href = "#contact" className="btn max-w-2xl h-12 flex justify-center items-center text-lg md:w-4/12">Contact us</a>

          <h3 className="text-center font-normal md:font-medium text-sm md:text-left leading-5 md:w-6/12">
            Let’s have a converstation to make your business great.
          </h3>
        </div>
      </div>
    </header>
  );
};

export default Hero;

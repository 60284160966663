
import  angular from "../assets/angular.png"
import  java from "../assets/java.png"
import  microservices from "../assets/microservices.png"
import springboot from "../assets/springboot.png"
import Gear from "../animations/Gear";
const SecondaryService = () => {
    return (
        <section className="flex flex-col gap-5 md:gap-10 my-32 md:my-40 md:flex-row items-center justify-between" id = "web">
         <Gear />
          <div className="flex flex-col gap-5 md:w-5/12" >
            <h3 className="text-primary2 text-2xl font-bold leading-8">Web App Development</h3>
            <p className="text-base leading-6">We offer front-end services using Google's Angular framework, as well as back-end services using Java, Microservices, and Spring Boot.</p>
            <div className="grid grid-cols-2 grid-rows-2 md:grid-rows-1 md:flex gap-y-5 gap-x-5 md:gap-10 md:justify-start w-full mx-auto mt-10">
              <div className="flex flex-col items-center gap-3">
                <img src={angular} alt="angular" className="" />
                <p className="">  Angular</p>
              </div>
              <div className="flex flex-col items-center gap-3">
                <img src={java} alt="java" className="" />
                <p className="">  Java</p>
              </div>
              <div className="flex flex-col items-center gap-3">
                <img src={springboot} alt="sprinboot" className="" />
                <p className=""> Springboot</p>
              </div>
              <div className="flex flex-col items-center gap-3">
                <img src={microservices} alt="microservices" className="" />
                <p className="">Microservices</p>
              </div>
            </div>
          </div>
        </section>
      );
}
 
export default SecondaryService;
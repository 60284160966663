import { Mouse, CaretDown} from "phosphor-react";

const AboutHero = () => {
    return ( 
        <>
        <div className="h-24 md:hidden"></div>
        <section className="md:h-[90vh] py-24 md:py-0 md:flex md:mb-20 justify-center items-center flex-col text-center relative">
            <h1 className="text-3xl md:text-5xl font-bold leading-10 text-primary2">Our Vision</h1>
            <p className="leading-6 w-9/12 mx-auto mt-5 md:text-lg md:font-medium">To encode technology and future-proof business transformation</p>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 hidden md:flex items-start gap-3">
            <div className="flex flex-col items-center animate-bounce">
            <Mouse size={25} />
            <CaretDown size = {15} /> 
            </div>
            <p>Scroll down</p>
        </div>
        </section>
        <div className="h-24 md:hidden"></div>
        </>
     );
}
 
export default AboutHero;
import mounish from "../assets/mounish.jpg";
import vijayalakshmi from "../assets/vijayalakshmi.jpg";
import amalraj from "../assets/amalraj.jpg";
const Testimonial = () => {
  return (
    <>
      <h2 className="text-3xl md:text-5xl font-bold leading-10 text-primary2 text-center mt-32 mb-10">
        Testimonials
      </h2>

      <section className="flex flex-col gap-5 md:gap-10 md:flex-row ">
        <div className=" md:w-4/12">
          <div className="flex items-center gap-5 ">
            <img src={amalraj} alt="" className="rounded-full my-5 w-12 h-12" />
            <div className="">
              <p className="font-semibold md:text-lg">Amalraj</p>
              <p className="">CEO of Finally Pictures</p>
            </div>
          </div>
          <div className="">
            <p className="leading-6">
              Working with Calpyte has been an amazing experience. Their work
              has been timely, exceptional and collaborative and we can feel a
              strong foundation of integrity with Calpyte.
            </p>
          </div>
        </div>
        <div className=" md:w-4/12">
          <div className="flex items-center gap-5 ">
            <img src={mounish} alt="" className="rounded-full my-5 w-12 h-12" />
            <div className="">
              <p className="font-semibold md:text-lg">Mounish G</p>
              <p className="">Full Stack Developer</p>
            </div>
          </div>
          <div className="">
            <p className="leading-6">
              It doesn't matter where you work, but it is about whose leadership
              you﻿ are under. Calpyte helped me in the smooth transition
              from a passionate coder to a passionate professional coder.
            </p>
          </div>
        </div>
        <div className=" md:w-4/12">
          <div className="flex items-center gap-5">
            <img
              src={vijayalakshmi}
              alt=""
              className="rounded-full my-5 w-12 h-12"
            />
            <div className="">
              <p className="font-semibold md:text-lg">Vijayalakshmi</p>
              <p className="">Developer</p>
            </div>
          </div>
          <div className="">
            <p className="leading-6">
              I personally love the culture inside the company. Working around
              like minded people always brings the best out of everyone. We are people driven.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

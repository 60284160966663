import Helmet from "react-helmet";
import Carousal from "../careers/Carousel";
import Form from "../careers/Form";
import Hero from "../careers/Hero";
import Oppurtunities from "../careers/Opportunities";
import Primary from "../careers/Primary";

const Careers = () => {
    return ( 
        <>
    
         <Helmet>
            <title>Calpyte - Careers</title>
            <meta name = "description" content="Careers - We give our employees the opportunity to advance in their careers."/>
        </Helmet>
        <Hero/>
        <Oppurtunities />
        <Primary />
<Carousal />
<Form/>
        </>
     );
}
 
export default Careers;
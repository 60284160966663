
import Forms from "../components/Forms";

const Contact = () => {
    return (  
        <>
         <Forms title = "Contact us" content = "Feel free contact us and let's work together to knock down barriers for your company." />

        
        </>

    );
}
 
export default Contact;
import bhaarath from "../assets/bhaarath-about.jpg";

const OurTeam = () => {
  return (
    <section className="my-32 md:my-40">
      <h2 className="text-4xl md:text-5xl font-bold leading-10 text-primary2 my-10 text-center">
        Our Founder
      </h2>
      <div className="flex flex-col md:flex-row gap-5 md:justify-evenly md:gap-10 sm:w-6/12 md:w-10/12 mx-auto">
        <div className="md:w-4/12">
          <img
            src={bhaarath}
            alt="bhaarath-founder"
            className=" block mx-auto"
          />
          <h4 className="text-xl font-semibold text-center mt-5">Bhaarath B</h4>
          <p className="text-lg font-normal text-center">Founder</p>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;

import image1 from "../assets/tertiary-home.png"
import image2 from "../assets/profile.jpeg"
const Tertiary = () => {
    return ( 
        <section className="my-32 md:my-40 flex flex-col gap-5 md:flex-row-reverse md:justify-evenly md:items-center">
            <img src={image1} alt="calpyte" className="block mx-auto sm:w-5/12" />
            <div className="md:w-6/12 ">
                <p className="text-lg md:text-2xl font-medium leading-7">
                “We stand for being a reliable and relentless technology partner with providing a best-in class product for your needs as well as support ”
                </p>
                <div className="flex items-center gap-5 text-primary2">
                    <img src={image2} alt="calpyte" className="rounded-full my-5 w-12 h-12" />
                    <div className="">
                        <p className="font-semibold md:text-lg">Bhaarath B</p>
                        <p className="">(Founder and COO)</p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Tertiary;
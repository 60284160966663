const Quotes = () => {
    return ( 
        <section className="mt-32 text-center md:w-7/12 mx-auto">
            <h3 className="text-2xl font-medium leading-8 mb-5">
            “There will always be change. In calpyte we help companies to change and evolve as they grow”
            </h3>
            <p className="mt-2 font-normal text-primary2 tracking-wide">- Bhaarath B</p>
            <p className="font-normal text-primary2 tracking-wide">(Founder and COO)</p>
        </section>
     );
}
 
export default Quotes;
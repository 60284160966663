import arrow from "../assets/arrow.png";
import glyph from "../assets/glyph.png";
import globe from "../assets/globe.png";

const Oppurtunities = () => {
  return (
    <section className="">
      <section className="mt-40">
        <h2 className="text-4xl md:text-5xl font-bold leading-10 text-primary2 my-20 md:text-center">
          Opportunites in Calpyte
        </h2>
        <div className="flex flex-col md:flex-row gap-14 justify-between md:w-11/12 mx-auto">
          <div className="flex flex-col gap-3">
            <h4 className="font-medium text-lg flex flex-col md:flex-row items-center gap-5">
              <img src={globe} alt="" className="w-10" />
              <span className="">Real World Exposure</span>
            </h4>
            <p className="leading-6 text-center md:text-left">
              We believe that working on real-world problems will always result
              in the most effective learning.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="font-medium text-lg flex flex-col md:flex-row items-center gap-5">
              <img src={arrow} alt="" className="w-10" />
              <span className="">Career Growth</span>
            </h4>
            <p className="leading-6 text-center md:text-left">
              With exceptional mentorship, everyone in the Calpyte Community can
              progress and advance in their careers.
            </p>
          </div>
          <div className="flex flex-col  gap-3">
            <h4 className="font-medium text-lg flex flex-col md:flex-row items-center gap-5">
              <img src={glyph} alt="" className="w-8" />
              <span className="">Work Life Harmony</span>
            </h4>
            <p className="leading-6 text-center md:text-left">
              We strive to foster a culture in which all of our people achieve
              their professional and personal objectives with passion.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Oppurtunities;

import { Link } from "react-router-dom";

const Secondary = () => {
  return (
    <section className="my-32 md:my-48">
         <div className="text-center md:text-left">
            <h2 className="text-3xl md:text-5xl font-bold leading-10 text-primary2 text-center">Our Services</h2>
            <p className="leading-6 mt-5 md:text-lg md:font-medium mx-auto w-10/12 text-center">We can build apps from A to Z that are necessary for your product in the best way possible in the mobile and web app development arena.</p>
        </div>
    <div className="flex flex-col md:flex-row md:justify-evenly gap-10 my-10" >
 
 
      <Link to ="/services" className="flex flex-col items-center   gap-3 md:w-4/12 hover:text-primary2">
        <svg
          width="50"
          height="50"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=""
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 8.75C3.75 7.36929 4.86929 6.25 6.25 6.25H33.75C35.1307 6.25 36.25 7.36929 36.25 8.75V31.25C36.25 32.6307 35.1307 33.75 33.75 33.75H6.25C4.86929 33.75 3.75 32.6307 3.75 31.25V8.75ZM33.75 8.75H6.25V31.25H33.75V8.75Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 15C3.75 14.3096 4.30964 13.75 5 13.75H35C35.6904 13.75 36.25 14.3096 36.25 15C36.25 15.6904 35.6904 16.25 35 16.25H5C4.30964 16.25 3.75 15.6904 3.75 15Z"
            fill="currentColor"
          />
        </svg>
        <h5 className="text-2xl font-bold leading-8 ">Web App Development</h5>
        <p className="text-lg font-normal text-center leading-7">
          We create the ideal web app solution for your industry's requirements.
        </p>
        <span
          to="/services#web"
          className="text-base font-medium leading-6 flex gap-2  items-center text-primary2 "
        >
          <span className="">Learn More</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </span>
      </Link>
      <Link to ="/services" className="flex flex-col items-center gap-3 md:w-4/12 hover:text-primary2">
        <svg
          width="50"
          height="50"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=""
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.25 33.75C11.25 34.4404 11.8096 35 12.5 35L27.5 35C28.1904 35 28.75 34.4404 28.75 33.75L28.75 6.25C28.75 5.55964 28.1904 5 27.5 5L12.5 5C11.8096 5 11.25 5.55964 11.25 6.25L11.25 33.75ZM12.5 37.5C10.4289 37.5 8.75 35.8211 8.75 33.75L8.75 6.25C8.75 4.17893 10.4289 2.5 12.5 2.5L27.5 2.5C29.5711 2.5 31.25 4.17893 31.25 6.25L31.25 33.75C31.25 35.8211 29.5711 37.5 27.5 37.5L12.5 37.5Z"
            fill="currentColor"
          />
          <path
            d="M20 28.75C18.9645 28.75 18.125 29.5895 18.125 30.625C18.125 31.6605 18.9645 32.5 20 32.5C21.0355 32.5 21.875 31.6605 21.875 30.625C21.875 29.5895 21.0355 28.75 20 28.75Z"
            fill="currentColor"
          />
        </svg>

        <h5 className="text-2xl font-bold leading-8 ">Mobile App Development</h5>
        <p className="text-lg font-normal leading-7 text-center ">
        We create custom mobile applications that are tailored to the needs of the company.
        </p>
        <span
          className="text-base font-medium leading-6 flex gap-2 items-center text-primary2 "
        >
          <span className="">Learn More</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </span>
      </Link>
    </div>
    </section>
  );
};

export default Secondary;

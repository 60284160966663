import emailjs from '@emailjs/browser';
import { useRef } from 'react';

const Forms = ({title, content}) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1g3pmop', 'template_t8uyhr5', form.current, 'JTA67EjzERFsK2WcP')
          .then((result) => {
            //   console.log(result.text);
            alert("Thank you for contacting us. We will get back to you shortly.");
          }, (error) => {
            //   console.log(error.text);
            alert("Something went wrong. Please try again later.");
          });
      };
    return (
        <section className="my-32 md:my-40 md:flex md:flex-row md:justify-evenly gap-10" id = "contact">
        <div className="flex flex-col gap-5 md:w-5/12 mt-20">
          <h5 className="text-3xl font-bold md:text-4xl">{title}</h5>
          <p className="text-lg font-medium leading-7">
            {content}
          </p>
          <div className="flex md:justify-start justify-center gap-5 md:gap-10 items-center">
            <div className="text-primary2 flex flex-col items-center gap-3">
              <svg
                width="40"
                height="40"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.24742 4.3415C8.43731 4.12448 8.71164 4 9 4H23C23.2884 4 23.5627 4.12448 23.7526 4.3415L30.7526 12.3415C31.0905 12.7277 31.0812 13.3071 30.7311 13.6823L16.7311 28.6823C16.5419 28.885 16.2772 29 16 29C15.7228 29 15.4581 28.885 15.2689 28.6823L1.26895 13.6823C0.918761 13.3071 0.909461 12.7277 1.24742 12.3415L8.24742 4.3415ZM9.45377 6L3.3477 12.9784L16 26.5344L28.6523 12.9784L22.5462 6H9.45377Z"
                  fill="#3742FA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9998 4C16.3107 4 16.604 4.14462 16.7932 4.39131L22.9307 12.3913C23.1472 12.6735 23.1975 13.0495 23.0628 13.3787L16.9253 28.3787C16.7716 28.7545 16.4058 29 15.9998 29C15.5938 29 15.2281 28.7545 15.0743 28.3787L8.93679 13.3787C8.80209 13.0495 8.8524 12.6735 9.06891 12.3913L15.2064 4.39131C15.3957 4.14462 15.6889 4 15.9998 4ZM15.9998 6.64287L11.0054 13.1529L15.9998 25.3593L20.9943 13.1529L15.9998 6.64287Z"
                  fill="#3742FA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 13C1 12.4477 1.44772 12 2 12H30C30.5523 12 31 12.4477 31 13C31 13.5523 30.5523 14 30 14H2C1.44772 14 1 13.5523 1 13Z"
                  fill="#3742FA"
                />
              </svg>
              <p className="">High-quality</p>
            </div>
            <div className="text-primary2 flex flex-col items-center gap-3">
              <svg
                width="40"
                height="40"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H26C26.5304 5 27.0391 5.21071 27.4142 5.58579C27.7893 5.96086 28 6.46957 28 7V14.3375C28 25.5236 18.5028 29.2257 16.6406 29.8448C16.2264 29.9931 15.7736 29.9931 15.3595 29.8448C13.4972 29.2257 4 25.5236 4 14.3375V7C4 6.46957 4.21071 5.96086 4.58579 5.58579ZM26 7H6L6 14.3375C6 24.1201 14.2747 27.3787 16 27.9501C17.7253 27.3787 26 24.1201 26 14.3375V7Z"
                  fill="#3742FA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.2236 12.3097C22.6048 12.7093 22.5899 13.3423 22.1903 13.7236L14.8528 20.7236C14.4662 21.0924 13.8579 21.0921 13.4716 20.723L9.80912 17.223C9.40983 16.8414 9.39547 16.2084 9.77704 15.8091C10.1586 15.4098 10.7916 15.3955 11.1909 15.777L14.1631 18.6174L20.8097 12.2765C21.2093 11.8952 21.8423 11.9101 22.2236 12.3097Z"
                  fill="#3742FA"
                />
              </svg>
  
              <p className="">Reliable-partner</p>
            </div>
          </div>
        </div>
        <form action="" className="flex flex-col gap-5 mt-5 md:w-6/12" ref={form} onSubmit={sendEmail}>
          <input type="text" className="form-input" placeholder="Your name" name = "name" required/>
          <div className="flex flex-col gap-3 md:flex-row ">
            <input type="email" className="form-input md:w-6/12" placeholder="Your email" name = "email" required/>
            <input type="phone" className="form-input md:w-6/12" placeholder="Your phone" name = "phone" required/>
          </div>
          <textarea name="message" id="" cols="30" rows="10" className="form-input h-32" placeholder="Your Message" required></textarea>
       
      <button className="btn text-lg">Send</button>
        </form>
      </section>
      );
}
 
export default Forms;
import { useEffect, useRef } from "react";
import "./Graph.css";

const Graph = () => {


  const arrowRef = useRef();
  const whiteRef = useRef();
  const pieRef = useRef();

  useEffect(() => {
    // Revieling the sections
    const svg = document.querySelector(".graph-svg");

    const revobsCallback = (entries, observer) => {
      const [entry] = entries;
      // console.log(entry);
      if (!entry.isIntersecting) {
        arrowRef.current.classList.add("arrow");
        arrowRef.current.classList.remove("arrow-1");

        whiteRef.current.classList.add("white");
        whiteRef.current.classList.remove("white-1");

        pieRef.current.classList.add("pie");
        pieRef.current.classList.remove("pie-1");
        // console.log("Not shown")
      }
      if (entry.isIntersecting) {
        arrowRef.current.classList.remove("arrow");
        arrowRef.current.classList.add("arrow-1");

        whiteRef.current.classList.remove("white");
        whiteRef.current.classList.add("white-1");

        pieRef.current.classList.remove("pie");
        pieRef.current.classList.add("pie-1");
        // console.log("Shown")
      }
    };

    const revielingSectionObserver = new IntersectionObserver(revobsCallback, {
      root: null,
      threshold: 1,
    });

    revielingSectionObserver.observe(svg);
    return () => revielingSectionObserver.disconnect();
  }, []);

  return (
    <>
      <svg
        viewBox="0 0 425 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="graph-svg sm:w-5/12 "
      >
        <g id="Frame 15">
          <g id="Group 45">
            <g id="Group 47">
              <g id="Group">
                <path
                  id="Vector"
                  d="M274.021 156.13C271.438 156.127 268.96 155.1 267.133 153.274C265.307 151.448 264.28 148.97 264.277 146.39V44.5858C264.28 42.0062 265.307 39.5282 267.133 37.7019C268.96 35.8756 271.437 34.8488 274.02 34.8457H406.097C408.679 34.8488 411.154 35.8754 412.981 37.7019C414.807 39.5283 415.834 42.0069 415.84 44.5875V146.388C415.834 148.969 414.807 151.448 412.981 153.274C411.154 155.101 408.679 156.127 406.096 156.13H274.021Z"
                  fill="#F9FAFC"
                  stroke="#E1E1E1"
                  strokeWidth="2"
                />
                <path
                  id="Vector_2"
                  d="M263.283 56.9214H417.311"
                  stroke="#E1E1E1"
                  strokeWidth="4"
                />
                <path
                  id="Vector_3"
                  d="M274.577 49.2316C276.835 49.2316 278.663 47.4038 278.663 45.1462C278.663 42.8886 276.835 41.0608 274.577 41.0608C272.323 41.0608 270.492 42.8886 270.492 45.1462C270.492 47.4038 272.323 49.2316 274.577 49.2316Z"
                  fill="#FF7300"
                />
                <path
                  id="Vector_4"
                  d="M286.959 49.2316C289.213 49.2316 291.044 47.4038 291.044 45.1462C291.044 42.8886 289.213 41.0608 286.959 41.0608C284.701 41.0608 282.874 42.8886 282.874 45.1462C282.874 47.4038 284.701 49.2316 286.959 49.2316Z"
                  fill="#FF7300"
                />
                <path
                  id="Vector_5"
                  d="M299.334 49.2316C301.592 49.2316 303.42 47.4038 303.42 45.1462C303.42 42.8886 301.592 41.0608 299.334 41.0608C297.08 41.0608 295.252 42.8886 295.252 45.1462C295.252 47.4038 297.08 49.2316 299.334 49.2316Z"
                  fill="#FF7300"
                />
              </g>
              <g id="pie" className="pie" ref={pieRef}>
                <path
                  id="Vector_6"
                  d="M327.964 108.846H294.754C294.703 108.846 294.703 108.846 294.703 108.897C294.754 131.736 313.193 150.175 336.032 150.175V116.917C331.581 116.917 327.964 113.297 327.964 108.846Z"
                  fill="#10CAB7"
                />
                <path
                  id="Vector_7"
                  d="M336.032 100.778V67.5683C336.032 67.517 336.032 67.517 335.984 67.517C313.193 67.5683 294.754 86.0073 294.703 108.798C294.703 108.846 294.703 108.846 294.754 108.846H327.964C327.964 104.395 331.533 100.778 336.032 100.778Z"
                  fill="#FFB300"
                />
                <path
                  id="Vector_8"
                  d="M344.103 108.846C344.103 113.297 340.483 116.917 336.032 116.917V150.127C336.032 150.175 336.032 150.175 336.083 150.175C358.922 150.127 377.361 131.688 377.361 108.846H344.103Z"
                  fill="#3742FA"
                />
                <path
                  id="Vector_9"
                  d="M340.191 101.952L356.77 73.1938V73.1425C350.51 69.4771 343.321 67.5691 336.083 67.5691C336.032 67.5691 336.032 67.5691 336.032 67.6172V100.827C337.501 100.778 338.918 101.17 340.191 101.952Z"
                  fill="#332C49"
                />
                <path
                  id="Vector_10"
                  d="M344.104 108.846H377.313C377.361 108.846 377.361 108.846 377.361 108.798C377.361 94.1237 369.537 80.527 356.87 73.1931H356.818L340.239 101.951C342.587 103.417 344.056 106.011 344.104 108.846Z"
                  fill="#FF7300"
                />
              </g>
            </g>
            <g id="Group_3">
              <g id="Group_4">
                <path
                  id="Vector_11"
                  d="M91.0409 272.88H161.879"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_12"
                  d="M91.0409 272.879V216.472"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_13"
                  d="M87.7697 241.71C100.244 241.71 99.25 258.77 108.101 258.77C121.216 258.77 123.525 222.374 138.276 222.374C153.028 222.374 151.071 243.025 161.237 243.025"
                  stroke="#3C2C5B"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_14"
                  d="M126.123 237.798C129.028 237.798 131.382 235.444 131.382 232.539C131.382 229.634 129.028 227.28 126.123 227.28C123.218 227.28 120.864 229.634 120.864 232.539C120.864 235.444 123.218 237.798 126.123 237.798Z"
                  fill="white"
                />
                <path
                  id="Vector_15"
                  d="M104.157 272.559V269.609"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_16"
                  d="M117.273 272.559V269.609"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_17"
                  d="M130.388 272.559V269.609"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_18"
                  d="M143.504 272.559V269.609"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_19"
                  d="M156.651 272.559V269.609"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                id="Vector_20"
                d="M95.2092 190.017H171.21C176.661 190.017 181.119 194.442 181.119 199.926V275.926C181.119 281.378 176.693 285.835 171.21 285.835H95.2092C89.7577 285.835 85.3002 281.41 85.3002 275.926V199.926C85.3002 194.442 89.7256 190.017 95.2092 190.017Z"
                fill="#332C49"
              />
              <path
                id="Vector_21"
                d="M97.7744 267.429H168.612"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_22"
                d="M97.7744 267.428V211.021"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_23"
                d="M94.5036 236.258C106.978 236.258 105.984 253.318 114.835 253.318C127.95 253.318 130.259 216.922 145.01 216.922C159.762 216.922 157.805 237.573 167.971 237.573"
                stroke="#10CAB7"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="white"
                className=" white"
                ref={whiteRef}
                d="M132.857 232.347C135.762 232.347 138.116 229.993 138.116 227.088C138.116 224.182 135.762 221.829 132.857 221.829C129.952 221.829 127.598 224.182 127.598 227.088C127.598 229.993 129.952 232.347 132.857 232.347Z"
                fill="white"
              />
              <path
                id="Vector_25"
                d="M110.89 267.108V264.158"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_26"
                d="M124.006 267.108V264.158"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_27"
                d="M137.122 267.108V264.158"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_28"
                d="M150.237 267.108V264.158"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_29"
                d="M163.385 267.108V264.158"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path
              id="Vector 24"
              d="M22.2486 20.7145L36.6823 45.7145H7.81481L22.2486 20.7145ZM22.2486 151.904V154.404H19.7486V151.904H22.2486ZM186.427 151.904L161.427 166.338V137.47L186.427 151.904ZM24.7486 43.2145L24.7486 151.904H19.7486L19.7486 43.2145H24.7486ZM22.2486 149.404H163.927V154.404H22.2486V149.404Z"
              fill="#332C49"
            />
            <g id="Group 46">
              <rect
                id="Rectangle 51"
                x="8.67169"
                y="1"
                width="185.962"
                height="166.015"
                rx="16"
                fill="#F9FAFC"
                stroke="#E1E1E1"
                strokeWidth="2"
              />
              <mask
                id="mask0_561_4023"
                maskUnits="userSpaceOnUse"
                x="17"
                y="50"
                width="164"
                height="114"
              >
                <rect
                  id="Rectangle 149"
                  x="17"
                  y="50"
                  width="164"
                  height="114"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_561_4023)">
                <g id="graph">
                  <path
                    id="Vector_30"
                    d="M122.178 84.1919H138.854V151.855H122.178V84.1919Z"
                    fill="#FF7300"
                  />
                  <path
                    id="Vector_31"
                    d="M153.605 64.5551H170.28V149.855H153.605V64.5551Z"
                    fill="#10CAB7"
                  />
                  <path
                    id="Vector_32"
                    d="M62.2122 91.8892H78.5668V151.856H62.2122V91.8892Z"
                    fill="#3742FA"
                  />
                  <path
                    id="Vector_33"
                    d="M92.035 103.112H108.71V151.855H92.035V103.112Z"
                    fill="#FFB300"
                  />
                  <path
                    id="Vector_34"
                    d="M32.0361 121.955H48.6504V151.82H32.0361V121.955Z"
                    fill="#332C49"
                  />
                  <path
                    id="Vector_35"
                    d="M147.089 151.82H23.7309"
                    stroke="black"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
            <g id="Group_5">
              <path
                id="Vector_36"
                d="M326.239 305.971L334.563 298.759L336.866 277.581C332.261 270.866 326.89 264.497 320.867 258.629L290.406 255.518L298.308 281.532C308.63 289.703 314.614 295.343 314.652 299.717L314.691 301.1C314.768 301.138 322.939 303.553 326.239 305.971Z"
                fill="#E59763"
              />
              <path
                id="Vector_37"
                d="M343.08 287.439C341.124 283.95 339.053 280.765 336.866 277.581C330.497 282.183 328.887 290.969 333.107 297.607L343.08 287.439Z"
                fill="#332C49"
              />
              <path
                id="Vector_38"
                d="M231.209 329.297L245.979 316.136C247.092 307.888 247.281 299.41 246.63 291.123L226.411 265.495L215.21 291.2C217.509 304.322 218.394 312.99 215.63 315.754C215.438 315.947 215.249 316.136 215.095 316.29L214.941 316.444C214.825 316.559 214.71 316.636 214.594 316.752L220.504 329.335H231.209V329.297Z"
                fill="#E59763"
              />
              <path
                id="Vector_39"
                d="M243.946 327.34C244.789 323.771 245.479 319.936 245.979 316.136C239.033 316.328 233.049 320.702 230.747 327.263L243.946 327.34Z"
                fill="#332C49"
              />
              <path
                id="Vector_40"
                d="M251.924 95.8817C251.886 96.6481 251.004 69.6374 251.004 64.343C251.004 55.1364 246.861 51.6442 237.613 51.378C237.269 51.378 236.923 52.9878 236.577 52.9878C229.022 52.9878 223.766 50.7623 220.655 55.5981C218.891 58.3592 218.202 63.5381 217.586 68.0661C217.548 68.0661 214.594 68.6786 214.402 69.9453C214.213 71.4043 216.704 73.3605 216.82 73.4759L216.743 73.7453C216.053 77.7345 215.322 81.8392 217.663 85.2544C219.276 87.5569 222.076 88.8588 225.414 88.8588C226.719 88.8588 228.06 88.6311 229.288 88.2463L229.365 95.112V95.9201L251.924 95.8817Z"
                fill="#E59763"
              />
              <path
                id="Vector_41"
                opacity="0.3"
                d="M232.167 86.4824C230.978 87.2488 230.477 87.5182 229.403 88.1307C229.326 88.1692 229.288 88.2461 229.288 88.3231L229.326 91.5844C229.326 91.8153 229.596 91.8922 229.711 91.6998L232.472 86.7871C232.588 86.5946 232.357 86.367 232.167 86.4824Z"
                fill="#191919"
              />
              <path
                id="Vector_42"
                d="M222.653 68.7586C223.265 68.7586 223.801 68.2616 223.801 67.6106C223.801 66.9949 223.304 66.4594 222.653 66.4594C222.037 66.4594 221.502 66.9564 221.502 67.6106C221.502 68.2231 222.037 68.7586 222.653 68.7586Z"
                fill="#191919"
              />
              <path
                id="Vector_43"
                d="M259.021 158.991L267.769 104.819C266.772 103.132 265.659 101.596 264.431 100.294C259.868 95.3426 252.232 95.3041 252.155 95.3041H229.519C229.519 95.3041 229.022 95.3041 228.637 95.3041C226.681 95.3041 221.729 95.4581 217.933 96.6863L216.435 138.121L219.927 159.029L259.021 158.991Z"
                fill="#FFE779"
              />
              <path
                id="Vector_44"
                opacity="0.3"
                d="M230.632 158.838L220.158 126.228L216.358 138.084L219.85 158.838H230.632Z"
                fill="black"
              />
              <path
                id="Vector_45"
                d="M263.972 164.596C269.533 163.676 280.083 159.109 280.083 148.444C280.083 137.242 274.789 116.103 267.692 104.633L258.944 158.805L263.972 164.596Z"
                fill="#E59763"
              />
              <path
                id="Vector_46"
                d="M219.85 158.763L250.619 158.801H258.944C272.871 170.884 277.36 191.026 280.814 210.594C283.536 226.054 284.649 232.461 304.599 245.506C310.393 249.303 315.88 253.715 320.867 258.628L313.004 266.607L296.437 259.869L305.634 274.204L298.384 281.608C296.697 280.303 294.933 278.921 293.051 277.503C286.455 272.324 278.935 266.453 271.222 259.741C260.672 250.57 252.155 235.187 245.748 219.993C244.981 221.718 244.289 223.408 243.676 225.133C237.92 241.209 238.687 248.96 244.827 278.077C245.748 282.567 246.322 286.941 246.63 291.123L235.044 291.161L227.136 274.367L225.183 291.2H215.21C214.671 288.054 214.058 284.754 213.443 281.223C211.371 269.791 208.992 256.861 207.536 242.857C203.774 206.986 219.658 159.26 219.85 158.763Z"
                fill="#22272E"
              />
              <path
                id="Vector_47"
                d="M220.655 55.4442C223.766 50.6084 228.945 48.2707 236.503 48.2707C236.846 48.2707 237.193 48.2707 237.539 48.2707C240.377 48.3477 242.907 48.9986 245.056 50.1499C245.392 50.5379 243.523 48.3092 242.907 47.1964C239.572 40.7893 242.025 32.8846 248.432 29.5463C254.84 26.2081 262.744 28.6645 266.083 35.0716C269.421 41.4787 266.964 49.3834 260.557 52.7185C256.684 54.7516 248.445 52.6704 248.663 52.8339C251.389 55.6366 252.96 59.5489 252.96 64.1923C252.96 65.4173 252.883 67.6043 252.845 70.3686L252.768 72.8218C255.147 72.2093 258.867 71.4813 263.087 71.4813C270.418 71.4813 276.402 73.5914 280.815 77.773C286.837 83.449 290.175 89.9716 290.788 97.1099C291.288 102.709 289.867 106.929 289.255 108.08C291.48 109.385 293.321 112.223 294.395 115.908C295.508 119.743 295.546 123.925 294.549 127.648C293.244 132.519 288.831 140.732 274.292 140.732C260.711 140.732 253.153 134.209 249.202 128.684C242.256 119.054 241.798 106.968 244.366 99.2584C236.465 92.8513 237.805 80.9189 237.844 80.8034C237.921 80.7649 244.212 76.9296 244.944 72.2093C245.21 70.3686 244.636 68.6401 243.215 67.0303C242.295 66.0715 241.182 65.5712 239.838 65.5712C236.769 65.5712 233.47 68.1431 232.511 68.948C232.511 68.9865 232.472 68.9865 232.472 68.9865C229.634 67.1842 228.252 63.769 227.297 61.1715C227.293 61.1587 225.568 61.4666 223.955 61.4666C220.655 61.4666 218.853 60.1614 218.853 60.1229C219.238 58.3592 219.85 56.8264 220.655 55.4442Z"
                fill="#191919"
              />
              <path
                id="Vector_48"
                d="M191.307 155.807C204.082 155.807 216.323 138.276 216.435 138.122C216.512 138.007 225.26 126.228 224.494 112.416C224.109 105.281 222.345 101.369 220.924 99.3713C219.507 97.3767 218.202 96.7642 217.971 96.6872C217.932 96.6872 217.932 96.6872 217.894 96.6872C211.064 98.9512 205.311 106.356 203.698 108.581C198.711 115.408 192.381 125.115 191.537 126.459C191.499 126.536 191.384 126.536 191.345 126.459C189.963 124.195 173.849 98.3355 168.057 93.6183C166.868 92.6595 165.909 92.1592 165.104 92.1592C163.914 92.1592 163.529 93.3489 163.453 93.8107C163.453 93.8877 163.337 93.9262 163.26 93.8877C162.151 93.0026 155.243 87.8237 152.559 87.8237C152.482 87.8237 152.405 87.8237 152.328 87.8237C150.754 88.0546 150.484 89.1673 150.411 89.6291C150.411 89.706 150.334 89.7445 150.257 89.706C149.987 89.5906 149.336 89.3982 148.682 89.3982C147.916 89.3982 147.338 89.6291 146.957 90.1261C145.806 91.6622 146.957 93.195 146.957 93.2335H146.918C146.88 93.2335 146.841 93.272 146.764 93.272C146.226 93.5413 145.806 94.0384 145.613 94.6156C145.421 95.1896 145.498 95.7669 145.767 96.3024C146.687 98.5279 152.174 102.902 153.976 104.284C154.092 104.361 153.976 104.515 153.861 104.476C152.136 103.899 147.8 102.52 145.883 102.52C145.382 102.52 145.078 102.594 144.962 102.786C144.693 103.171 144.577 103.556 144.693 104.015C144.962 105.166 146.572 106.51 149.182 107.815C152.213 109.348 154.858 111.034 155.705 112.035C156.087 112.493 156.471 112.955 156.853 113.414C158.735 115.716 160.499 117.865 163.299 118.554C163.337 118.554 163.376 118.593 163.376 118.631C163.568 119.478 166.024 128.261 170.36 136.932C174.58 145.372 181.484 155.464 190.617 155.769C190.81 155.769 190.845 155.769 190.999 155.769L191.307 155.807Z"
                fill="#E59763"
              />
              <path
                id="Vector_49"
                d="M220.542 327.418C220.119 323.275 217.971 319.282 214.632 316.714C213.212 317.98 211.563 319.093 210.066 320.244C207.112 322.431 204.351 324.541 202.623 327.225L202.815 328.03L220.542 327.418Z"
                fill="#332C49"
              />
              <path
                id="Vector_50"
                d="M326.085 304.781C322.823 302.401 318.988 301.1 314.999 301.1H314.691C314.576 303.017 314.345 304.935 314.04 306.929C313.54 310.614 313.04 314.065 313.809 317.172L314.002 317.518L326.085 304.781Z"
                fill="#332C49"
              />
              <path
                id="Vector_51"
                d="M223.727 75.2014C223.073 76.0448 221.999 76.5066 220.925 76.3526"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_52"
                d="M239.379 72.4402L236.577 74.6272"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_53"
                d="M233.469 85.6744C232.206 86.4825 230.516 87.4799 229.288 88.2078"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_54"
                d="M255.721 190.106C254.073 203.456 249.699 210.863 245.748 219.993"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_55"
                d="M261.285 146.138L265.505 147.289"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_56"
                d="M191.423 126.612V133.019"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_57"
                d="M150.411 89.7825C150.411 89.7825 158.466 94.7306 163.837 98.9507"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_58"
                d="M146.957 92.8126C146.957 92.8126 154.438 98.758 159.809 102.978"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_59"
                d="M163.376 93.8906C164.873 94.9649 166.714 95.8083 168.057 97.0365"
                stroke="#191919"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_60"
                d="M258.982 158.802H219.85"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_61"
                d="M242.487 332.635C242.525 332.596 243.138 330.64 243.946 327.263L202.623 327.225C201.587 328.835 201.052 330.717 201.052 332.635H242.487Z"
                fill="#808080"
              />
              <path
                id="Vector_62"
                d="M316.57 321.969L345.575 292.39C345.575 292.351 344.732 290.472 343.042 287.4L313.809 317.172C314.229 318.977 315.188 320.626 316.57 321.969Z"
                fill="#808080"
                stroke="#808080"
                strokeWidth="2"
              />
            </g>
          </g>
          <mask
            id="mask1_561_4023"
            maskUnits="userSpaceOnUse"
            x="17"
            y="0"
            width="174"
            height="109"
          >
            <rect
              id="Rectangle 150"
              x="17"
              width="174"
              height="109"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask1_561_4023)">
            <g id="arrow" className="arrow" width="5" ref={arrowRef}>
              <path
                id="Vector 25"
                d="M41.4282 89.796L64.0881 68.5614L100.087 92.8296L170.316 33.7559"
                stroke="black"
                strokeWidth="10"
                strokeLinecap="round"
              />
              <path
                id="Vector 26"
                d="M174.76 45.4423L159.281 27.9048L182.271 23.2135L174.76 45.4423Z"
                fill="black"
                stroke="black"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Graph;

import Forms from "../components/Forms";
import Helmet from "react-helmet"
import Hero from "../home/Hero";
import Logos from "../home/Logos";
import Primary from "../home/Primary";
import Quotes from "../home/Quotes";
import Secondary from "../home/Secondary";
import Tertiary from "../home/Tertiary";
import Testimonial from "../home/Testimonial";

const Home = () => {
  return (
    <main className="">
 <Helmet>
 <title>Calpyte - Encoding the future</title>
            <meta name = "description" content="To encode technology and future-proof business transformation. We provide your company a technological boost so that you can focus on your operations without interruption. We build web apps and mobile applications that seamlessly transform your business."/>
 </Helmet>

      <Hero />
      <Logos />
      <Testimonial />

      <Quotes />
      <Primary />
      <Secondary />
      <Tertiary />
     <Forms title = "Contact us" content = "Feel free contact us and let's work together to knock down barriers for your company." />
    </main>
  );
};

export default Home;

import Map from "../animations/Map";

const Primary = () => {
  return (
    <section className="mt-32 flex flex-col md:flex-row justify-evenly items-center gap-5">
<Map/>
      <div className="flex flex-col gap-5 md:w-5/12">
          <h4 className="text-2xl font-bold leading-8">
          We provide your company a technological boost so that you can focus on your operations without interruption.
          </h4>
          <p className="text-base font-normal leading-6">
          You don't have to be concerned about the constantly changing technological world. We've come to look after you.
          </p>
          <ul className="text-base text-primary2 font-medium leading-6 flex flex-col gap-3">
              <li className="">Free Tech Consultation</li>
              <li className="">Best Technology </li>
              <li className="">Resource Management</li>
              <li className="">Dedicated Tech Team</li>
          </ul>
      </div>
    </section>
  );
};

export default Primary;

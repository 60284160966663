const Benifits = () => {
    return (  
        <section className="my-32 md:my-40">
            <h2 className="text-4xl md:text-5xl font-bold leading-10 text-primary2 my-10 md:text-center">Our Beliefs</h2>
            <div className="flex flex-col md:flex-row gap-10 justify-between">
                <div className="flex flex-col gap-3">
                    <h4 className="font-medium text-lg">Aspiration</h4>
                    <p className="leading-6">We believe that the desire to progress propels each individual to think and act in new ways, providing meaning, fulfilment, and joy in the process.</p>
                </div>
                <div className="flex flex-col gap-3">
                    <h4 className="font-medium  text-lg">Integrity</h4>
                    <p className="leading-6">We act with honesty and compassion at all times. We follow through on what we say, recognise when we've made a mistake, and work hard to put things right.</p>
                </div>
                <div className="flex flex-col gap-3">
                    <h4 className="font-medium  text-lg">Forward Thinking</h4>
                    <p className="leading-6">We, as a firm, have great aspirations and excitement for what the future holds for us, and we aim to embrace and experiment in our journey with relentless energy.</p>
                </div>
            </div>
        </section>
    );
}
 
export default Benifits;
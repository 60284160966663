import visionImage from '../assets/vision-cover.png';

const Primary = () => {
    return ( 
        <section className=" flex flex-col gap-5 md:flex-row items-center justify-between">
            <div className="flex flex-col gap-5 md:w-6/12">
            <h3 className="text-2xl font-bold leading-8 text-primary2 md:text-4xl lg:leading-relaxed">Calpyte stands for Relentless</h3>
            <p className="text-lg">We are never compromising and relentless in building the best technology solution for you.</p>
            </div>
            <img src={visionImage} alt="" className="sm:w-5/12" />
          
        </section>
     );
}
 
export default Primary;
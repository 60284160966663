import android from "../assets/android.png";
import apple from "../assets/apple.png";
import flutter from "../assets/flutter.png"
import Mobile from "../animations/Mobile";
const TertiaryServices = () => {
    return ( 
        <section className="flex flex-col gap-5 md:gap-5 my-32 md:my-40  md:flex-row-reverse items-center justify-between" id = "mobile">
        <Mobile/>
        <div className="flex flex-col gap-5 md:w-5/12">
          <h3 className="text-primary2 text-2xl font-bold leading-8">Mobile App Development</h3>
          <p className="text-base leading-6">We offer both Android and iOS mobile app development services. To create the apps, we use the Flutter framework, which is powered by Google.</p>
          <div className="flex md:justify-start items-center gap-8 justify-center">
            <div className="flex flex-col items-center gap-3">
              <img src={android} alt="" className="android" />
              <p className="">Android</p>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img src={apple} alt="apple" className="" />
              <p className="">iOS</p>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img src={flutter} alt="flutter" className="" />
              <p className="">Flutter</p>
            </div>
          </div>
        </div>
      </section>
     );
}
 
export default TertiaryServices;